import React, { useEffect, useState } from 'react'
import { Dropdown, Nav, Navbar } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import { SharlicWhiteLogo } from 'apps/shared/components/Logo'
import { navType } from 'apps/shared/components/NavItem'
import NavList from 'apps/shared/components/NavList'
import ProfileModal from 'apps/vendor/components/Modals/ProfileModal'
import useAppDispatch from 'hooks/useAppDispatch'
import useUser from 'hooks/useUser'
import { vendorNavItems, customerNavItems } from 'routes'
import { logoutUser } from 'state/user/actions'

import { Line } from '../NavList/NavList.styled'
import {
    NavRow,
    SettingsDropdown,
    SideBar,
    StyledToggle,
    TopBar,
    UserBadge,
    UserIcon,
} from './Navbar.styled'

export default function Navbars() {
    const [expanded, setExpanded] = useState(false)
    const { user } = useUser()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [showProfileModal, setShowProfileModal] = useState(false)

    const logout = () => {
        dispatch(logoutUser()).then(() => {
            navigate(0)
        })
    }

    useEffect(() => {
        const navRow = [].slice.call(document.getElementsByClassName('navItem'))

        navRow.forEach((item: HTMLElement) => {
            if (item.getAttribute('data-path') === location.pathname) {
                item.classList.add('active')
            } else {
                item.classList.remove('active')
            }
        })
    }, [location.pathname])

    const handleClick = (e: any) => {
        if (e.tagName === 'BUTTON') {
            const navRow = [].slice.call(
                document.getElementsByClassName('navItem'),
            )

            navRow.forEach((item: HTMLElement) => {
                if (item !== e) {
                    item.classList.remove('active')
                }
            })

            e.classList.add('active')
            if (window.innerWidth < 576) {
                setExpanded(false)
            }
        }
    }

    const getFilteredNavItems = (navList: navType[]) => {
        return navList.filter((navItem: navType) =>
            navItem.permissions
                ? navItem.permissions.every((permission: string) =>
                      user?.permissions?.includes(permission),
                  )
                : navItem,
        )
    }

    const getNavItemList = () => {
        let filteredNavItems: navType[] = []

        switch (user?.user_type) {
            case 'vendor':
                filteredNavItems = getFilteredNavItems(vendorNavItems)
                break

            case 'customer':
                filteredNavItems = getFilteredNavItems(customerNavItems)
                break
            default:
                break
        }

        return filteredNavItems
    }

    const renderUserIcon = () => {
        return (
            <SettingsDropdown>
                <Line className="badge-line" />
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {user && user.first_name && user.last_name ? (
                        <UserIcon>
                            <span className="icon">
                                {`${user?.first_name[0]}${user?.last_name[0]}`}
                            </span>
                            <span className="name">
                                {user?.first_name} {user?.last_name}
                            </span>
                        </UserIcon>
                    ) : (
                        <UserIcon>
                            <i className="fa-solid fa-user icon" />
                        </UserIcon>
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => {
                            setShowProfileModal(!showProfileModal)
                        }}
                    >
                        <div className="fa-solid fa-user profile" />
                        Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            logout()
                        }}
                    >
                        {/* eslint-disable-next-line max-len */}
                        <div className="fa-solid fa-right-from-bracket sign-out" />
                        Sign Out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </SettingsDropdown>
        )
    }

    return (
        <SideBar>
            {showProfileModal && (
                <ProfileModal
                    onClose={() => setShowProfileModal(!showProfileModal)}
                    onSubmit={() => setShowProfileModal(!showProfileModal)}
                />
            )}
            <Navbar collapseOnSelect expand="xl" expanded={expanded}>
                <StyledToggle
                    open={expanded}
                    onClick={() => setExpanded(!expanded)}
                >
                    <div />
                    <div />
                    <div />
                </StyledToggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Navbar.Brand>
                        <TopBar>
                            <SharlicWhiteLogo />
                        </TopBar>
                    </Navbar.Brand>
                    <NavRow
                        className="navRow"
                        onClick={(e) => handleClick(e.target)}
                    >
                        <Nav>
                            <NavList navItemList={getNavItemList()} />
                        </Nav>
                    </NavRow>
                    <UserBadge>{renderUserIcon()}</UserBadge>
                </Navbar.Collapse>
            </Navbar>
        </SideBar>
    )
}
